<template>
  <div id="home">
    <header data-aos="fade">
      <div class="row flex-row-reverse flex-sm-row">
        <div class="col-4 col-lg-3">
          <h1 id="header-doc">
            抹茶で<span style="color: #bd6c47">ほっこり</span><br />
            <span style="color: #4c7c2c; margin-top: 7rem">
              <ruby>一福<rt>いっぷく</rt></ruby>
            </span>
            しませんか。
          </h1>
        </div>
      </div>
    </header>
    <div id="top-concept">
      <div id="morning" class="row d-flex justify-content-center">
        <div class="col-11 col-lg-5">
          <div class="picture">
            <img src="@/assets/images/home/top-image02-morning.jpg" alt="朝に抹茶を飲んでいる" />
          </div>
        </div>
        <div class="col-11 col-lg-5">
          <h2>
            <span style="margin-right: 10vw">一日の始まり、<br /></span>
            <span style="color: #bd6c47">朝</span>起きての
            <span style="color: #4c7c2c">
              <ruby>一福<rt>いっぷく</rt></ruby>
            </span>
          </h2>
          <p>
            朝起きたら、一日の始まり。<br />
            まずは、一杯の抹茶を飲んで、<br />
            今日を乗り越えるための準備をしてみませんか。
          </p>
          <div class="button">
            <router-link to="/item">商品紹介ページへ</router-link>
          </div>
        </div>
      </div>
      <div id="evening" class="row d-flex flex-lg-row-reverse justify-content-center">
        <div class="col-11 col-lg-5">
          <div class="picture">
            <img src="@/assets/images/home/top-image03-evening.jpg" alt="仕事や家事の合間に抹茶を飲んでいる" />
          </div>
        </div>
        <div class="col-11 col-lg-5">
          <h2>
            仕事や家事の合間、<br />
            <span style="color: #bd6c47">休憩</span>時間に
            <span style="color: #4c7c2c">
              <ruby>一福<rt>いっぷく</rt></ruby>
            </span>
          </h2>
          <p>
            一日も残り半分。<br />
            仕事や家事を頑張るあなたに。<br />
            抹茶を飲みながら、少し休憩してみませんか。
          </p>
          <div class="button">
            <router-link to="/item">商品紹介ページへ</router-link>
          </div>
        </div>
      </div>
      <div id="night" class="row d-flex justify-content-center">
        <div class="col-11 col-lg-5">
          <div class="picture">
            <img src="@/assets/images/home/top-image04-night.jpg" alt="夜寝る前に抹茶を飲んでいる" />
          </div>
        </div>
        <div class="col-11 col-lg-5">
          <h2>
            <span style="margin-right: 10vw"><span style="color: #bd6c47">夜</span>寝る前、</span><br />
            <span style="margin-left: 5vw">
              明日に備えて
              <span style="color: #4c7c2c">
                <ruby>一福<rt>いっぷく</rt></ruby>
              </span>
            </span>
          </h2>
          <p>
            もう少しで一日も終わり。<br />
            寝る前には、抹茶を飲んで、<br />
            明日に備えて心を落ち着かせてみませんか。
          </p>
          <div class="button">
            <router-link to="/item">商品紹介ページへ</router-link>
          </div>
        </div>
      </div>
    </div>
    <div id="top-kodawari">
      <div id="kodawari-picture" class="row mx-0">
        <h2>商品のこだわり</h2>
        <div class="caption">
          <span>
            (有)藤二商会<br />
            取締役社長 利田氏
          </span>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-11 col-lg-12">
          <p>
            福乃茶には、茶市場や生産家から厳選して仕入れた<span style="color: #4c7c2c">宇治産の茶葉</span
            >のみを使用。<br />
            古都京都の地で創業約100年の歴史を持つ製茶問屋が、徹底した衛生管理・品質管理の下で製茶し、加工。<br />
            「<span style="color: #bd6c47">安全・安心・高品質</span>」をこだわり抜いた宇治抹茶をお届けします。
          </p>
        </div>
      </div>
    </div>
    <div id="top-product">
      <div id="product-wrapper">
        <h2>商品一覧</h2>
        <div class="row justify-content-center">
          <div class="col-11 col-lg-6">
            <div id="product-picture">
              <img src="@/assets/images/home/top-image06-product.png" alt="商品の写真" style="max-width: 800px" />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-9 col-lg-4">
            <p class="price">
              <span style="background: linear-gradient(transparent 70%, rgba(189, 108, 71, 0.7)); font-size: 1.6rem">
                1,500円(税込)
              </span>
            </p>
            <table>
              <tbody>
                <tr>
                  <th>内容量</th>
                  <td>30g(抹茶粉末)</td>
                </tr>
                <tr>
                  <th>賞味期限</th>
                  <td>製造日から1年</td>
                </tr>
              </tbody>
            </table>
            <div class="mt-5">
              <div class="button">
                <router-link to="/item#fukunocha">商品紹介ページへ</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-11 col-lg-6">
            <div id="product-picture">
              <img src="@/assets/images/item/item-image04.jpg" alt="商品の写真" style="max-width: 800px" />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-9 col-lg-4">
            <p class="price">
              <span style="background: linear-gradient(transparent 70%, rgba(189, 108, 71, 0.7)); font-size: 1.6rem">
                11,000円(税込)
              </span>
            </p>
            <table>
              <tbody>
                <tr>
                  <th>内容</th>
                  <td>茶楽本体、茶筅、電池2本</td>
                </tr>
              </tbody>
            </table>
            <div class="button">
              <router-link to="/item#charaku">商品紹介ページへ</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="top-news">
      <h2>お知らせ</h2>
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-xl-8">
          <ul>
            <li v-for="article in news" :key="article">
              <router-link :to="{ name: 'article', params: { id: article.id } }">
                <div>
                  {{ dayjs(article.createdAt).format("YYYY年MM月DD日") }}
                  {{ article.title }}
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="button">
        <router-link to="/news">ニュース一覧へ</router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import dayjs from "dayjs";

const client = inject("client");

const news = ref();

(async () => {
  const response = await client.get({
    endpoint: "news",
    queries: { limit: 5 },
  });
  news.value = response.contents;
})();
</script>

<style scoped>
header {
  min-height: 50vw;
  background-image: url(@/assets/images/home/top-image01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

#header-doc {
  writing-mode: vertical-rl;
  font-size: 2.7rem;
  color: #6f6f6f;
  font-weight: 500;
  line-height: 5rem;
  letter-spacing: 0.5rem;
  padding: 10% 30%;
}

#header-pic {
  width: 100%;
  height: 800px;
  object-fit: cover;
}

#top-concept {
  width: 100%;
  position: relative;
}

#morning {
  padding-top: 200px;
  background-position: right top 50px;
  background-image: url(@/assets/images/home/top-concept01.png);
  background-repeat: no-repeat;
}

#morning .picture {
  position: relative;
}

#morning .picture::after {
  position: absolute;
  left: -80px;
  bottom: -80px;
  z-index: -1;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/images/home/top-image02-back.png);
}

#morning img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

#evening {
  margin-top: 150px;
}

#evening .picture {
  position: relative;
}

#evening .picture::after {
  position: absolute;
  right: -80px;
  bottom: -80px;
  z-index: -1;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/images/home/top-image03-back.png);
}

#evening img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

#night {
  margin-top: 150px;
}

#night .picture {
  position: relative;
}

#night .picture::after {
  position: absolute;
  left: -80px;
  bottom: -80px;
  z-index: -1;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/images/home/top-image04-back.png);
}

#night img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

#top-concept h2 {
  font-size: 2.5rem;
  line-height: 5rem;
  letter-spacing: 0.3rem;
}

#top-concept p {
  font-size: 1.3rem;
  font-weight: normal;
  text-align: start;
  padding: 30px 0px 0px 60px;
}

#top-kodawari {
  margin-top: 200px;
}

#kodawari-picture {
  position: relative;
  width: 100%;
  height: 400px;
  background-image: url(@/assets/images/home/top-image05-kodawari.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.2);
  background-blend-mode: lighten;
}

#kodawari-picture .caption span {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: inline;
  font-size: 1rem;
  font-weight: bold;
  color: white;
}

#top-kodawari h2 {
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0.5rem;
  color: white;
  margin-top: 150px;
  margin-bottom: 100px;
}

#top-kodawari p {
  font-size: 1.5rem;
  font-weight: normal;
  margin-top: 50px;
  line-height: 3rem;
  letter-spacing: 0.2rem;
  padding: 0 6vw;
}

#top-product {
  margin-top: 100px;
  position: relative;
}

#product-wrapper {
  padding-bottom: 150px;
  background-position: left bottom;
  background-image: url(@/assets/images/home/top-concept02.png);
  background-repeat: no-repeat;
}

#top-product h2 {
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 0.3rem;
  color: #096534;
  margin-bottom: 50px;
}

#product-picture {
  position: relative;
}

#product-picture::after {
  position: absolute;
  right: -50px;
  bottom: -50px;
  z-index: -1;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/images/home/top-image03-back.png);
}

#top-product img {
  width: 100%;
}

#top-product p {
  text-align: center;
  margin-top: 80px;
}

#top-product table {
  text-align: start;
  width: 100%;
}

#top-product table tr * {
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 2rem;
}

#top-news {
  margin: 50px 0px;
}

#top-news h2 {
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 0.3rem;
  color: #096534;
}

#top-news ul {
  margin-top: 50px;
  padding-left: 0px;
}

#top-news ul li {
  text-align: start;
  list-style: none;
  color: #486930;
  border: solid 1px;
  border-color: #4c7c2c;
  margin: 30px;
  padding: 10px 20px;
}

#top-news ul li a {
  color: var(--bs-dark);
  text-decoration: none;
}

@media screen and (max-width: 1140px) {
  header {
    background-image: url(@/assets/images/home/top-image01.jpg);
    min-height: 800px;
  }
  #header-doc {
    font-size: 2rem;
    line-height: 4rem;
  }

  #top-concept h2 {
    font-size: 2rem;
    line-height: 4rem;
    letter-spacing: 0.5rem;
  }

  #top-concept p {
    font-size: 1.2rem;
    font-weight: normal;
    text-align: start;
    padding: 30px 8%;
  }

  #top-kodawari p {
    font-size: 1.2rem;
    font-weight: normal;
    margin-top: 80px;
    line-height: 3rem;
    letter-spacing: 0.2rem;
  }
}

@media screen and (max-width: 992px) {
  header {
    min-height: 700px;
    background-size: cover;
  }

  #header-doc {
    writing-mode: vertical-rl;
    font-size: 2rem;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: 0.5rem;
    padding: 10% 25%;
  }
  #morning {
    padding-top: 120px;
    background-position: right -80px top;
    background-image: url(@/assets/images/home/top-concept01.png);
  }

  #evening {
    margin-top: 50px;
  }

  #night {
    margin-top: 50px;
  }

  #morning .picture::after {
    all: initial;
  }

  #evening .picture::after {
    all: initial;
  }

  #night .picture::after {
    all: initial;
  }

  #top-concept h2 {
    font-size: 2rem;
    line-height: 4rem;
    letter-spacing: 0.5rem;
  }

  #top-concept p {
    font-size: 1.2rem;
    font-weight: normal;
    text-align: start;
    padding: 30px 8%;
  }

  #top-kodawari {
    margin-top: 50px;
  }

  #kodawari-picture {
    height: 300px;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.3);
    background-blend-mode: lighten;
  }

  #top-kodawari h2 {
    font-size: 2rem;
    letter-spacing: 0.2rem;
    margin-top: 120px;
  }

  #top-product {
    margin-top: 50px;
  }

  #product-wrapper {
    padding-bottom: 150px;
    background-position: left -120px bottom;
    background-image: url(@/assets/images/home/top-concept02.png);
    background-repeat: no-repeat;
  }

  #product-picture::after {
    right: -20px;
    bottom: -20px;
  }

  #top-product h2 {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.3rem;
  }

  #top-kodawari p {
    text-align: start;
    font-size: 1rem;
    font-weight: normal;
    margin-top: 80px;
    line-height: 2rem;
    letter-spacing: 0.2rem;
  }
}

@media screen and (max-width: 575px) {
  header {
    min-height: 600px;
    background-image: url(@/assets/images/home/top-image01-mobile.png);
  }
  #header-doc {
    writing-mode: vertical-rl;
    font-size: 2rem;
    color: #6f6f6f;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: 0.2rem;
    padding: 0 20vw 0 0;
  }
  #morning {
    background-position: initial;
    background-image: initial;
    background-repeat: initial;
  }

  #kodawari-picture {
    position: relative;
    width: 100%;
    height: auto;
    background-image: url(@/assets/images/home/top-image05-mobile.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(255, 255, 255, 0.2);
    background-blend-mode: lighten;
  }

  #kodawari-picture .caption span {
    font-size: 0.5rem;
  }

  #top-kodawari h2 {
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    margin-top: 40px;
  }

  #top-concept h2 {
    font-size: 1.8rem;
    line-height: 3rem;
    letter-spacing: 0.1rem;
  }

  #top-concept p {
    font-size: 1.3rem;
    font-weight: normal;
    text-align: start;
    padding: 30px 0px 0px 30px;
  }

  #product-wrapper {
    background-position: initial;
    background-image: initial;
    background-repeat: initial;
    padding-bottom: 50px;
  }

  #product-picture::after {
    all: initial;
  }
}
</style>
