import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);

//Bootstrap import
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@/assets/scss/custom.scss";

// importing AOS css style globally
import "aos/dist/aos.css";
import AOS from "aos/dist/aos";

AOS.init({
  offset: 300,
  delay: 100,
  duration: 500,
  easing: "ease-in",
  once: true,
});

// import vue-i18n
import { createI18n } from "vue-i18n";

const i18n = new createI18n({
  locale: "ja",
  messages: {
    // 翻訳用のjson
    ja: require("@/assets/i18n/ja.json"),
    en: require("@/assets/i18n/en.json"),
  },
});

app.use(i18n);

// import axios
import axios from "./plugins/useAxios";
app.use(axios);

// import microCMS
import microCMS from "./plugins/useMicroCMS";
app.use(microCMS);

//yup 日本語化
import { setLocale } from "yup";
import * as ja from "yup-locale-ja";
setLocale(ja.suggestive);

//Google analystic
import VueGtag from "vue-gtag";
app.use(VueGtag, { config: { id: process.env.VUE_APP_GA_ID } }, router);

app.use(router);
app.mount("#app");
