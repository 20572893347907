<template>
  <div id="contact" class="container-xl">
    <div id="leadStepNav" class="delimiter">
      <div class="row">
        <p id="pageTitle">お問い合わせフォーム</p>
      </div>
      <div id="stepNav" class="row justify-content-center">
        <div class="col-12 col-lg-8">
          <ol class="d-flex justify-content-center">
            <li>
              <div class="circle" v-bind:class="{ stepActive: isActive[0] }">
                <span>お問い合わせ<br />内容入力</span>
              </div>
            </li>
            <li>
              <div class="circle" v-bind:class="{ stepActive: isActive[1] }">
                <span>確認</span>
              </div>
            </li>
            <li>
              <div class="circle" v-bind:class="{ stepActive: isActive[2] }">
                <span>完了</span>
              </div>
            </li>
          </ol>
          <p id="stepNavMessage" v-show="!isActive[2]">
            福乃茶に対するご質問・ご意見は、下記の入力フォームにてお寄せください<br />
            お問い合わせいただいた内容につきましては、5営業日以内にご返信いたします。
          </p>
          <div id="sendComplete" v-show="isActive[2]">
            <p>お問い合わせありがとうございました。</p>
            <p>ご入力いただいたメールアドレス宛に確認メールを送信しております。</p>
            <p>お問い合わせいただいた内容につきましては、5営業日以内にご返信いたします。</p>
            <div class="button">
              <router-link to="/">トップへ戻る</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent>
      <div id="form-content" v-show="isActive[0]">
        <div class="row delimiter">
          <div class="col-12 col-md-6">
            <div class="question">お問い合わせの種類<span class="required">必須</span></div>
          </div>
          <div class="col-12 col-md-6">
            <div class="radio-answer">
              <fieldset>
                <input type="radio" id="question" name="type" value="ご質問" v-model="formContent.type" checked />
                <label for="question">ご質問</label>
                <input type="radio" id="option" name="type" value="ご意見" v-model="formContent.type" />
                <label for="option">ご意見</label>
                <input type="radio" id="suggestion" name="type" value="ご提案" v-model="formContent.type" />
                <label for="suggetstion">ご提案</label>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="row delimiter">
          <div class="col-12 col-md-6">
            <div class="question">お問い合わせの内容<span class="required">必須</span></div>
            <p class="error-message">{{ textError }}</p>
          </div>
          <div class="col-12 col-md-6">
            <div class="textarea-answer">
              <textarea id="text" name="text" cols="30" rows="8" v-model="text" />
            </div>
          </div>
        </div>
        <div class="row delimiter">
          <div class="col-12 col-md-6">
            <div class="question">お名前<span class="required">必須</span></div>
            <p class="error-message">{{ nameError }}</p>
          </div>
          <div class="col-12 col-md-6">
            <div class="answer">
              <input type="text" id="name" name="name" v-model="name" />
            </div>
          </div>
        </div>
        <div class="row delimiter">
          <div class="col-12 col-md-6">
            <div class="question">メールアドレス<span class="required">必須</span></div>
            <p class="error-message">{{ emailError }}</p>
          </div>
          <div class="col-12 col-md-6">
            <div class="answer">
              <input type="email" id="email" name="email" v-model="email" />
            </div>
          </div>
        </div>
        <div class="row delimiter">
          <div class="col-12 col-md-6">
            <div v-cloak class="question">電話番号(ハイフン不要)</div>
            <span class="error-message">{{ telError }}</span>
          </div>
          <div class="col-12 col-md-6">
            <div class="answer">
              <input type="text" id="tel" name="tel" v-model="tel" />
            </div>
          </div>
        </div>
        <div class="row delimiter">
          <div class="button" style="margin: 50px 0px">
            <button v-on:click="nextStep">個人情報入力へ進む</button>
          </div>
        </div>
      </div>
      <div id="confirm-content" v-show="isActive[1]">
        <div class="row delimiter">
          <div class="col-12 col-md-6">
            <div class="question">お問い合わせの種類<span class="required">必須</span></div>
          </div>
          <div class="col-12 col-md-6">
            <div class="answer">
              {{ formContent.type }}
            </div>
          </div>
          <div class="row delimiter">
            <div class="col-12 col-md-6">
              <div class="question">お問い合わせの内容<span class="required">必須</span></div>
            </div>
            <div class="col-12 col-md-6">
              <div class="answer">
                {{ formContent.text }}
              </div>
            </div>
          </div>
          <div class="row delimiter">
            <div class="col-12 col-md-6">
              <div class="question">お名前<span class="required">必須</span></div>
            </div>
            <div class="col-12 col-md-6">
              <div class="answer">
                {{ formContent.name }}
              </div>
            </div>
          </div>
          <div class="row delimiter">
            <div class="col-12 col-md-6">
              <div class="question">メールアドレス<span class="required">必須</span></div>
            </div>
            <div class="col-12 col-md-6">
              <div class="answer">
                {{ formContent.email }}
              </div>
            </div>
          </div>
          <div class="row delimiter">
            <div class="col-12 col-md-6">
              <div class="question">電話番号(ハイフン不要)</div>
            </div>
            <div class="col-12 col-md-6">
              <div class="answer">
                <p v-if="formContent.tel == ``">記入なし</p>
                <p v-else>
                  {{ formContent.tel }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-cotent-center" style="margin: 50px 0px">
          <div class="col button-back">
            <button class="btn" v-on:click="backStep">お問い合わせ入力へ戻る</button>
          </div>
          <div class="col button">
            <button class="btn" v-on:click="submit">送信する</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { reactive, ref, inject } from "vue";
import { useField, useForm } from "vee-validate";
import { useLoading } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import * as yup from "yup";

const axios = inject("axios");

const $loading = useLoading();

const submit = async (e) => {
  if (formContent.tel == "") {
    formContent.tel = " (記入なし)";
  }
  const loader = $loading.show();
  setTimeout(async () => {
    const response = await axios.post(
      "https://8sckc2akwj.execute-api.ap-northeast-1.amazonaws.com/default/testFukunochaFunction",
      JSON.stringify(formContent)
    );
    if (response.status == 200) {
      //success
      loader.hide();
      nextStep(e);
    } else {
      loader.hide();
      alert("お問い合わせ中にエラーが発生しました。");
    }
  }, 1000);
};

const formSchema = yup.object({
  text: yup.string().required("テキストは必須項目です").max(1000),
  name: yup.string().required("名前は必須項目です").max(20),
  email: yup.string().required("メールアドレスは必須項目です").email(),
  tel: yup
    .string()
    .max(13)
    .matches(/^([0-9])/, () => {
      if (formContent.tel != "") return "数字以外は使えません。";
    }),
});

useForm({ validationSchema: formSchema });

const { value: text, errorMessage: textError } = useField("text");
const { value: name, errorMessage: nameError } = useField("name");
const { value: email, errorMessage: emailError } = useField("email");
const { value: tel, errorMessage: telError } = useField("tel");

const formContent = reactive({
  type: "ご質問",
  text: text,
  name: name,
  email: email,
  tel: tel,
});

let currentStep = 0;
let stepFlag = ref([true, false, false]);
const isActive = ref(stepFlag);
const nextStep = (e) => {
  if (checkStatus()) {
    currentStep += 1;
    let array = [false, false, false];
    array = array.map((element, index) => {
      if (index === currentStep) {
        element = true;
      } else {
        element = false;
      }
      return element;
    });
    stepFlag.value = array;

    if (tel.value == undefined) {
      tel.value = "";
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  if (text.value == undefined) {
    text.value = "";
  }
  if (name.value == undefined) {
    name.value = "";
  }
  if (email.value == undefined) {
    email.value = "";
  }
  e.preventDefault();
};

const checkStatus = () => {
  if (!(text.value && !textError.value?.length)) return false;
  if (!(name.value && !nameError.value?.length)) return false;
  if (!(email.value && !emailError.value?.length)) return false;
  return true;
};

const backStep = () => {
  currentStep -= 1;
  let array = [false, false, false, false];
  array = array.map((element, index) => {
    if (index === currentStep) {
      element = true;
    } else {
      element = false;
    }
    return element;
  });
  stepFlag.value = array;
};
</script>

<style scoped>
.stepActive {
  color: white;
  background: #438460 !important;
}

.isHidden {
  display: none;
}
.delimiter {
  position: relative;
  margin: 15px 0;
}

.delimiter::before {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--bs-gray-200);
  position: absolute;
  top: 0;
}

#contact {
  max-width: 1024px;
}

#leadStepNav ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#pageTitle {
  font-size: 1.5rem;
  text-align: start;
  margin: 15px 0;
  padding: 15px 0 0 0;
}

#stepNav {
  margin: 20px 0;
}

ol li .circle {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: var(--bs-gray-200);
  margin-right: 20px;
}

ol li:not(:first-child) .circle::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: -10%;
  transform: translateY(-50%);
  border: 10px solid transparent;
  border-left: 8px solid var(--bs-dark);
}

.circle span {
  font-size: 1rem;
  position: absolute;
  display: inline-block;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 120px;
  text-align: center;
}

#stepNavMessage {
  font-size: 1rem;
  margin: 20px;
  line-height: 2;
}

#sendComplete {
  text-align: start;
  font-size: 1.2rem;
  line-height: 3rem;
  margin-top: 50px;
}

.required {
  color: var(--bs-gray-100);
  background: var(--bs-red);
  margin-left: 20px;
  padding: 5px 10px;
}

.question {
  line-height: 500%;
}

.radio-answer input {
  line-height: 500%;
  margin-right: 5px;
}
.radio-answer label {
  line-height: 500%;
  margin-right: 15px;
}

.textarea-answer textarea {
  width: 100%;
  margin-top: 15px;
  border-radius: 3%;
}

.answer {
  text-align: start;
  white-space: pre-line;
  margin-top: 1.5rem;
}

.button button {
  background: #eee;
  border-radius: 3px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  min-width: 280px;
  max-width: 300px;
  padding: 10px 25px;
  color: #313131;
  transition: 0.3s ease-in-out;
  font-weight: 500;
  text-decoration: none;
}
.button button:after {
  content: "";
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 2rem;
  font-size: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: right 0.3s;
  width: 6px;
  height: 6px;
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor;
  transform: translateY(-50%) rotate(45deg);
}
.button button:hover {
  background: #4c7c2c;
  color: #fff;
}
.button button:hover:after {
  right: 1.4rem;
}
.error-message {
  text-align: center;
  color: var(--bs-red);
}

@media screen and (max-width: 576px) {
  ol li .circle {
    width: 20vw;
    height: 20vw;
    margin-right: 10px;
  }

  ol li:not(:first-child) .circle::after {
    left: -8%;
  }

  .circle span {
    font-size: 0.6rem;
    left: 0;
    top: 50%;
    width: 20vw;
  }

  #stepNavMessage {
    font-size: 0.8rem;
    line-height: 2;
    text-align: start;
    margin: 20px 0;
  }

  .question {
    line-height: 300%;
  }

  .radio-answer input {
    line-height: 300%;
    margin-right: 5px;
  }
  .radio-answer label {
    line-height: 300%;
    margin-right: 15px;
  }
  .answer {
    margin-top: 1rem;
  }

  .button-back {
    margin-bottom: 30px;
  }
}
</style>
