import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ConceptView from "../views/ConceptView.vue";
import ItemView from "../views/ItemView.vue";
import ContactView from "../views/ContactView.vue";
import NewsView from "../views/NewsView";
import ArticleView from "../views/ArticleView";
import PrivacyPolicyView from "@/views/PrivacyPolicyView";
import NotFoundErrorView from "@/views/NotFoundErrorView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "ホーム | 福乃茶",
      metaTags: [
        {
          name: "description",
          content:
            "京都宇治抹茶「福乃茶」公式オンラインショップです。宇治産の茶葉のみを加工した味わい深い抹茶です。普段の生活で、抹茶をより美味しく楽しむためのコツやコンテンツ情報も配信しています。至福・幸福の一時をあなたに。",
        },
        {
          name: "keywords",
          content: "京都、宇治、抹茶、お茶、飲み物",
        },
        {
          property: "og:url",
          content: "http://www.fukunocha.com/",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "京都宇治抹茶「福乃茶」公式オンラインショップ",
        },
        {
          property: "og:description",
          content:
            "京都宇治抹茶「福乃茶」公式オンラインショップです。宇治産の茶葉のみを加工した味わい深い抹茶です。至福・幸福の一時をあなたに。",
        },
        {
          property: "og:site_name",
          content: "福乃茶",
        },
        {
          property: "og:image",
          content: "http://www.fukunocha.com/og-image.jpg",
        },
      ],
    },
  },
  {
    path: "/concept",
    name: "concept",
    component: ConceptView,
    meta: {
      title: "コンセプト | 福乃茶",
    },
  },
  {
    path: "/item",
    name: "item",
    component: ItemView,
    meta: {
      title: "商品一覧 | 福乃茶",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
    meta: {
      title: "お問い合わせ | 福乃茶",
    },
  },
  {
    path: "/news",
    name: "news",
    component: NewsView,
    meta: {
      title: "ニュース | 福乃茶",
    },
  },
  {
    path: "/news/:id",
    name: "article",
    component: ArticleView,
    meta: {
      title: "ニュース | 福乃茶",
    },
  },
  {
    path: "/policy",
    name: "policy",
    component: PrivacyPolicyView,
    meta: {
      title: "プライバシーポリシー | 福乃茶",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFoundError",
    component: NotFoundErrorView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
