<template>
  <div id="notfound">
    <h1>お探しのページは見つかりません。</h1>
    <div class="button-back">
      <router-link to="/">トップへ戻る</router-link>
    </div>
  </div>
</template>

<style scoped>
#notfound {
  margin: 200px 0px;
}

#notfound .button-back {
  margin: 150px 0px;
}
</style>
