<template>
  <div id="concept">
    <header>
      <h1>コンセプト</h1>
    </header>
    <div id="concept-nav" class="row d-none d-md-flex flex-lg-row justify-content-center">
      <ul>
        <li>
          <router-link to="/concept#name">名前の由来</router-link>
        </li>
        <li>
          <router-link to="/concept#enjoy">抹茶の楽しみ方</router-link>
        </li>
        <li>
          <router-link to="/concept#anzen">安心・安全へのこだわり</router-link>
        </li>
        <li>
          <router-link to="/concept#area">産地のこだわり</router-link>
        </li>
        <li>
          <router-link to="/concept#maker">作り手のこだわり</router-link>
        </li>
        <li>
          <router-link to="/concept#development">商品開発のこだわり</router-link>
        </li>
      </ul>
    </div>
    <div id="name">
      <div class="row">
        <div class="col-sm-9 col-lg-8">
          <div class="salespoint">
            <img src="@/assets/images/concept/concept-image-product.png" />
            <div class="salescard-right">
              <div class="row d-flex card-content-right text-start">
                <div class="card-title row justify-content-start align-items-center">
                  <div class="col-6">
                    <h2>名前の由来</h2>
                  </div>
                  <div class="col-6">
                    <img src="@/assets/images/share/nav_logo.svg" alt="商品の写真" style="width: 150px" />
                  </div>
                </div>
                <p>
                  福乃茶の「福」には、幸福・至福の一時を抹茶を通じて一人ひとりに届けたい、そんな意味が込められています。<br />
                  日常に抹茶を添ることで、少しでも幸せを感じてほしい、これが私たちの想いです。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="enjoy">
      <div class="row flex-row-reverse">
        <div class="col-sm-9 col-lg-8">
          <div class="salespoint row flex-column-reverse">
            <div class="salescard-left">
              <div class="row d-flex card-content-left text-start">
                <div class="card-title row justify-content-start align-items-center">
                  <h2>抹茶の楽しみ方</h2>
                </div>
                <p>
                  朝起きた時、仕事や家事の休憩、夜寝る前...日々の生活の「一福」に、抹茶を飲んで<span
                    style="color: #bd6c47"
                    >ほっこり</span
                  >しませんか。<br />
                  抹茶は、体も心も温め、脳をリフレッシュさせるには最適な飲み物。毎日を頑張るあなたに、小さな幸せをお届けします。
                </p>
              </div>
            </div>
            <img src="@/assets/images/concept/concept-image03.jpg" alt="抹茶を点てている写真" />
          </div>
        </div>
      </div>
    </div>
    <div id="anzen">
      <div class="row">
        <div class="col-sm-9 col-lg-8">
          <div class="salespoint">
            <img src="@/assets/images/concept/concept-image04.jpg" alt="茶葉" />
            <div class="salescard-right">
              <div class="row d-flex card-content-right text-start">
                <div class="card-title row justify-content-start align-items-center">
                  <h2>安心・安全へのこだわり</h2>
                </div>
                <p>
                  国際標準機構が定めた食品安全の標準規格を取得した工場で加工。<br />
                  異物混入の心配もなく、安心・安全に抹茶を楽しむことができます。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="area">
      <div class="row flex-row-reverse">
        <div class="col-sm-9 col-lg-8">
          <div class="salespoint row flex-column-reverse">
            <div class="salescard-left">
              <div class="row d-flex card-content-left text-start">
                <div class="card-title row justify-content-start align-items-center">
                  <h2 style="margin-top: 20px">産地のこだわり</h2>
                </div>
                <p style="margin-bottom: 0px">
                  福乃茶に使用している茶葉は、全て宇治産。<br />
                  宇治の自然豊かな土壌で育った茶葉の中から厳選したものを使用しています。
                </p>
              </div>
            </div>
            <img src="@/assets/images/concept/concept-image05.jpg" alt="京都・宇治の風景" />
          </div>
        </div>
      </div>
    </div>
    <div id="maker">
      <div class="row">
        <div class="col-sm-9 col-lg-8">
          <div class="salespoint">
            <img src="@/assets/images/concept/concept-image10-kakou.jpg" alt="抹茶生産者の顔" />
            <div class="salescard-right">
              <div class="row d-flex card-content-right text-start">
                <div class="card-title row justify-content-start align-items-center">
                  <h2>作り手のこだわり</h2>
                </div>
                <p>
                  京都市で10人のみ選抜される茶審査技術大会で優勝経験を持つ茶師が加工。<br />
                  高品質なお茶を届けるため、茶市場に自ら足を運び茶葉の選定を行っています。数々の認証資格も取得。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="development">
      <div class="row flex-row-reverse">
        <div class="col-sm-9 col-lg-8">
          <div class="salespoint row flex-column-reverse">
            <div class="salescard-left">
              <div class="row d-flex card-content-left text-start">
                <div class="card-title row justify-content-start align-items-center">
                  <h2>商品開発のこだわり</h2>
                </div>
                <p>
                  福乃茶は、東北秋田の企業が商品開発から販売・運営を行っています。全国・世界へ宇治抹茶の魅力を伝え、多くの人に幸福を届けたい、これが福乃茶を開発した理由です。
                </p>
              </div>
            </div>
            <div class="picture">
              <img src="@/assets/images/concept/concept-image12-komachi.jpg" alt="商品開発者の顔" />
              <div class="caption">
                <span>
                  こまちソフトウェア(株)<br />
                  菅原・一ノ関
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="certification">
      <h2><span style="background: linear-gradient(transparent 70%, #dce2bf 0%)">認証資格</span></h2>
      <div class="row justify-content-center">
        <div class="col-9 col-lg-6">
          <div class="row justify-content-center flex-column">
            <div>
              <img src="@/assets/images/concept/concept-image07.jpg" alt="ISO22000・HACCPの認定証" />
            </div>
            <h3>ISO 22000・HACCP</h3>
            <p>
              ISO
              22000は「食品安全」のための国際規格です。製造過程での衛生基準をクリアするだけではなく、原材料の調達から消費までの安全・安心を確保するための総合的な食品安全対策となっています。<br />
              また、HACCPとは、FAO（国連食糧農業機関）とWHO（世界保健機関）の合同機関から発表されている、国際的な食品衛生管理規格のことです。
            </p>
          </div>
        </div>
        <div class="col-9 col-lg-6">
          <div class="row justify-content-center flex-column">
            <div>
              <img src="@/assets/images/concept/concept-image08-haral.png" alt="ハラールロゴ" />
              <img src="@/assets/images/concept/concept-image08-certification.jpg" alt="ハラール認定証" />
            </div>
            <h3>ハラール認証</h3>
            <p>
              イスラムの戒律に基づいて製造された「健康的・清潔・安全・高品質」な商品であることを審査によって承認するシステムであり、厳しい品質管理基準が求められます。ハラール製品はムスリムだけでなく、世界中の人々にとって健康的で良いものであるといえます。
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="workflow">
      <h2><span style="background: linear-gradient(transparent 70%, #dce2bf 0%)"> 生産〜販売までの流れ</span></h2>
      <p>～お客様に安全・安心な商品を届けるため、私たちは福乃茶に携わる全ての人が見えるような販売を心がけています～</p>
      <div class="row justify-content-center">
        <div class="col-9 col-md-4">
          <div class="row justify-content-center">
            <div class="first-circle d-flex flex-column justify-content-center">
              <h3>生産</h3>
              <p>生産者代表</p>
              <p>
                (株)仲碾茶加工<br />
                代表取締役 仲
              </p>
            </div>
          </div>
        </div>
        <div class="col-9 col-md-4">
          <div class="row justify-content-center">
            <div class="circle d-flex flex-column justify-content-center">
              <h3>加工</h3>
              <p>製茶問屋</p>
              <p>
                <a href="https://fujini.com">(有)藤二商会</a><br />
                取締役社長 利田
              </p>
            </div>
          </div>
        </div>
        <div class="col-9 col-md-4">
          <div class="row justify-content-center">
            <div class="third-circle d-flex flex-column justify-content-center">
              <h3>販売企画・サイト運営</h3>
              <p>
                <a href="https://www.komachi-softwear.com/">こまちソフトウェア(株)</a><br />
                代表取締役 福嶋
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="passion">
      <h2><span style="background: linear-gradient(transparent 70%, #dce2bf 0%)">作り手の思い</span></h2>
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-lg-4">
          <h3>生産者</h3>
          <img src="@/assets/images/concept/concept-image06.jpg" alt="茶畑" />
          <h4>「高品質への追及」</h4>
          <p>
            私たちは、歴史ある宇治茶を深く理解し、常に高品質だけを求めて生産しています。<br />
            また、日々変化する京都の気候に合わせるべく、徹底した栽培管理を心がけています。最近では、日本だけでなく輸出茶への取り組みにも力を入れ、世界に向けて宇治茶の素晴らしさを伝えていきたいと思っています。
          </p>
        </div>
        <div class="col-12 col-lg-4">
          <h3>加工者</h3>
          <img src="@/assets/images/concept/concept-image10.jpg" alt="抹茶加工者の顔写真" />
          <h4>「継続は力なり」</h4>
          <p>
            私たちの会社は世界遺産立ち並ぶ古都京都の地で 20世紀中頃から現在まで製茶問屋を営んでまいりました。<br />
            継続することで、初めてお茶の文化を売ることが出来る。そして、それはお茶に携わる身としての使命だと思っています。<br />
            日本から世界へ文化を売るため、日々お客様の元にお茶を届け続けています。
          </p>
        </div>
        <div class="col-12 col-lg-4">
          <h3>商品開発</h3>
          <img src="@/assets/images/concept/concept-image11-komachi.jpg" alt="商品開発者の顔写真" />
          <h4>「秋田から宇治の抹茶を発信」</h4>
          <p>
            福乃茶は、開発から運営まで、秋田県秋田市に拠点を置く こまちソフトウェア(株)が行っております。<br />
            宇治抹茶は日本が世界に誇るものの1つ。日本の一企業として、宇治抹茶の魅力を全国・世界へ発信してまいります。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#concept h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

#concept p {
  font-size: 0.8rem;
  line-height: 1rem;
  letter-spacing: 0.2rem;
}

header {
  position: relative;
  min-height: 50vw;
  background-image: url(@/assets/images/concept/concept-image01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

header h1 {
  font-size: 3rem;
  font-weight: bold;
  color: white;
  position: absolute;
  margin-top: 8%;
  margin-left: 8%;
}

#concept-nav ul li {
  display: inline-block;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  padding: 15px 5px 15px 0px;
}

#concept-nav ul li:not(:last-child):after {
  content: "/";
  color: black;
  display: inline-block;
  padding: 15px;
}

#concept-nav ul li a {
  color: #070808;
  text-decoration: none;
}

#name {
  margin-top: 100px;
}

.salespoint {
  position: relative;
}

.salescard-right {
  position: absolute;
  right: -20vw;
  bottom: 0px;
  width: 400px;
  height: 200px;
  background-color: white;
  padding: 20px;
}

.card-title {
  margin-bottom: 20px;
}

.card-content-right {
  position: relative;
}

.card-content-right::after {
  position: absolute;
  right: -50px;
  top: -100px;
  z-index: -1;
  content: "";
  display: block;
  width: 500px;
  height: 300px;
  background-size: cover;
}

#name .card-content-right::after {
  background-image: url(@/assets/images/concept/concept-image02-back.svg);
}

#enjoy {
  margin-top: 180px;
}

#enjoy img {
  width: 100%;
}

.salescard-left {
  position: absolute;
  left: -20vw;
  bottom: 0px;
  width: 400px;
  height: 200px;
  background-color: white;
  padding: 20px;
}

.card-content-left {
  position: relative;
}

.card-content-left::after {
  position: absolute;
  left: -150px;
  top: -100px;
  z-index: -1;
  content: "";
  display: block;
  width: 500px;
  height: 350px;
  background-size: cover;
}

#enjoy .card-content-left::after {
  background-image: url(@/assets/images/concept/concept-image03-back.svg);
}

#anzen {
  margin-top: 180px;
}
#anzen .card-content-right::after {
  background-image: url(@/assets/images/concept/concept-image04-back.svg);
}

#area {
  margin-top: 180px;
}

#area .card-content-left::after {
  background-image: url(@/assets/images/concept/concept-image03-back.svg); /*修正が必要*/
}

#maker {
  margin-top: 180px;
}
#maker .card-content-right::after {
  background-image: url(@/assets/images/concept/concept-image06-back.svg);
}

#development {
  margin-top: 180px;
}

#development .picture {
  position: relative;
  z-index: -99;
}

#development .caption {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: inline;
  font-size: 10px;
  font-weight: bold;
  color: white;
}

#development .card-content-left::after {
  background-image: url(@/assets/images/concept/concept-image03-back.svg); /*修正が必要*/
}

#certification {
  margin: 100px 10vw 0 10vw;
}

#certification img {
  width: auto;
  max-width: 100%;
  height: 200px;
}

#certification h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  margin-bottom: 50px;
}
#certification h3 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 30px 0px;
}

#certification p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 50px;
  padding: 10px 4vw;
}

#workflow {
  margin: 0 10vw;
}

#workflow h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  margin-bottom: 50px;
}

#workflow > p {
  text-align: center;
  font-size: 1.3vw;
  letter-spacing: 0.1rem;
  margin: 50px 0px;
}

#workflow p {
  text-align: center;
}

#workflow h3 {
  text-align: center;
  font-size: 1.5vw;
  letter-spacing: 0.1rem;
  padding-top: 20px;
}

#workflow .circle {
  position: relative;
  display: inline-block;
  width: 22vw;
  height: 22vw;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  border: solid 10px #387e1a;
  z-index: 0;
}

#workflow .circle::after {
  content: "";
  position: absolute;
  background-color: white;
  top: 50%;
  bottom: 0;
  left: -3vw;
  font-size: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4vw;
  height: 4vw;
  border-top: solid 7px #c2da92;
  border-right: solid 7px #c2da92;
  transform: translateY(-50%) rotate(45deg);
  z-index: 1;
}

#workflow .first-circle {
  position: relative;
  display: inline-block;
  width: 22vw;
  height: 22vw;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  border: solid 10px #c2da92;
  z-index: 0;
}

#workflow .third-circle {
  position: relative;
  display: inline-block;
  width: 22vw;
  height: 22vw;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  border: solid 10px #c2da92;
  z-index: 0;
}

#workflow .third-circle::after {
  content: "";
  position: absolute;
  background-color: white;
  top: 50%;
  bottom: 0;
  left: -3vw;
  font-size: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4vw;
  height: 4vw;
  border-top: solid 7px #387e1a;
  border-right: solid 7px #387e1a;
  transform: translateY(-50%) rotate(45deg);
  z-index: 1;
}

#passion {
  margin: 50px 7vw 100px 7vw;
}

#passion h2 {
  font-size: 2rem;
  letter-spacing: 0.3rem;
  margin-bottom: 60px;
}

#passion h3 {
  position: relative;
  display: inline-flex;
  font-size: 1.8rem;
  letter-spacing: 0.3rem;
  color: #328131;
  padding: 10px 70px;
  margin-bottom: 30px;
}

#passion h3:before,
h3:after {
  position: absolute;
  content: "";
}

#passion h3:after {
  top: 0;
  left: 0;
  width: 200px;
  height: 30px;
  border-top: 1px solid #707070;
  border-left: 1px solid #707070;
}

#passion h3:before {
  right: 0;
  bottom: 0;
  width: 200px;
  height: 30px;
  border-right: 1px solid #707070;
  border-bottom: 1px solid #707070;
}

#passion h4 {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.8rem;
  letter-spacing: 0.3rem;
  color: #438460;
  margin: 30px 0;
}

#passion p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 50px;
  padding: 5px 30px;
}

#passion img {
  width: 80%;
}

@media screen and (min-width: 1600px) {
  #concept h2 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  #concept p {
    font-size: 1.1rem;
    line-height: 2rem;
    letter-spacing: 0.2rem;
  }
  .salescard-right {
    right: -20vw;
    bottom: 0px;
    width: 500px;
    height: 300px;
    padding: 20px;
  }
  .card-content-right::after {
    right: -50px;
    top: -100px;
    width: 600px;
    height: 400px;
  }

  .salescard-left {
    width: 500px;
    height: 300px;
    padding: 20px;
  }
  .card-content-left::after {
    left: -200px;
    top: -50px;
    width: 600px;
    height: 450px;
    background-size: cover;
  }
}

@media screen and (max-width: 1200px) {
  #concept-nav ul li {
    display: inline-block;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    padding: 15px 5px 15px 0px;
  }

  #concept-nav ul li:not(:last-child):after {
    content: "/";
    color: black;
    display: inline-block;
    padding: 5px;
  }
  #concept h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  #concept p {
    font-size: 0.8rem;
    line-height: 1.5rem;
    letter-spacing: 0.1rem;
  }
  .salescard-right {
    right: -25vw;
    bottom: 0px;
    width: 400px;
    height: 200px;
    padding: 20px;
  }
  .card-content-right::after {
    right: -50px;
    top: -60px;
    width: 400px;
    height: 250px;
  }

  .salescard-left {
    left: -25vw;
    bottom: -50px;
    width: 400px;
    height: 200px;
    padding: 20px;
  }
  .card-content-left::after {
    left: -150px;
    top: -100px;
    width: 500px;
    height: 300px;
    background-size: cover;
  }
}
@media screen and (max-width: 992px) {
  #enjoy {
    margin-top: 250px;
  }
  #anzen {
    margin-top: 250px;
  }
  #area {
    margin-top: 200px;
  }
  #maker {
    margin-top: 200px;
  }
  #certification {
    margin-top: 200px;
  }
  .salescard-right {
    right: -25vw;
    bottom: -20vw;
    width: 400px;
    height: 200px;
    padding: 20px;
  }
  .card-content-right::after {
    right: -50px;
    top: -60px;
    width: 400px;
    height: 250px;
  }

  .salescard-left {
    left: -20vw;
    bottom: -15vw;
    width: 400px;
    height: 200px;
    padding: 20px;
  }
  .card-content-left::after {
    left: -150px;
    top: -100px;
    width: 500px;
    height: 300px;
    background-size: cover;
  }
}
@media screen and (max-width: 767px) {
  #workflow {
    margin: 0 8vw;
  }

  #workflow h2 {
    font-size: 1.3rem;
  }

  #workflow h3 {
    text-align: center;
    font-size: 5vw;
    letter-spacing: 0.1rem;
    padding-top: 20px;
  }

  #workflow p {
    font-size: 1rem;
  }

  #workflow .circle {
    width: 50vw;
    height: 50vw;
    margin-bottom: 25px;
  }

  #workflow .circle::after {
    top: -3%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    transform: translateY(-50%) rotate(135deg);
    z-index: 1;
  }

  #workflow .first-circle {
    width: 50vw;
    height: 50vw;
    margin-bottom: 25px;
  }

  #workflow .third-circle {
    width: 50vw;
    height: 50vw;
    margin-bottom: 25px;
  }

  #workflow .third-circle::after {
    top: -3%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    transform: translateY(-50%) rotate(135deg);
    z-index: 1;
  }
}
@media screen and (max-width: 576px) {
  header {
    min-height: 600px;
    background-image: url(@/assets/images/concept/concept-image01-mobile.jpg);
    opacity: 0.8;
  }
  header h1 {
    font-size: 2.5rem;
    letter-spacing: 0.3rem;
    font-weight: bold;
    color: #486930;
    background-color: rgba(255, 255, 255, 0.4);
    position: absolute;
    margin: 50px 0 0 0px;
    padding: 10px 0 10px 20px;
  }
  .salescard-right {
    position: relative;
    right: -5vw;
    bottom: -20px;
    width: 90vw;
    height: 240px;
    padding: 20px;
  }
  .card-content-right::after {
    right: -10px;
    top: -30px;
    width: 576px;
    height: 270px;
  }

  .salescard-left {
    position: relative;
    left: 0;
    bottom: -10px;
    width: 90vw;
    height: 250px;
    margin: 0 auto;
    padding: 20px;
  }
  .card-content-left::after {
    left: -120px;
    top: -170px;
    width: 700px;
    height: 430px;
  }
}
</style>
