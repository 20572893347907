<template>
  <div id="news">
    <h2>お知らせ</h2>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-xl-8" style="min-height: 800px">
        <ul>
          <li v-for="article in getItems" :key="article">
            <router-link :to="{ name: 'article', params: { id: article.id } }">
              <div>
                {{ dayjs(article.createdAt).format("YYYY年MM月DD日") }}
                {{ article.title }}
              </div>
            </router-link>
          </li>
        </ul>
        <div class="d-inline-flex">
          <paginate
            :page-count="getPageCount"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'＜'"
            :next-text="'＞'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            class="mx-auto"
          >
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from "vue";
import dayjs from "dayjs";

import Paginate from "vuejs-paginate-next";

import { useLoading } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
const $loading = useLoading();

const client = inject("client");
const news = ref();

(async () => {
  const loader = $loading.show();
  const response = await client.get({
    endpoint: "news",
    queries: { limit: 100 },
  });
  news.value = response.contents;
  loader.hide();
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
})();

const parPage = ref(10);
const currentPage = ref(1);

const clickCallback = (pageNum) => {
  currentPage.value = Number(pageNum);
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const getItems = computed(() => {
  let current = currentPage.value * parPage.value;
  let start = current - parPage.value;
  if (news.value) {
    return news.value.slice(start, current);
  } else {
    return "";
  }
});

const getPageCount = computed(() => {
  if (news.value) {
    return Math.ceil(news.value.length / parPage.value);
  } else {
    return 1;
  }
});
</script>

<style scoped>
#news {
  margin-top: 100px;
}

#news h2 {
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 0.3rem;
}

#news ul {
  margin-top: 50px;
  padding-left: 0px;
}

#news ul li {
  text-align: start;
  list-style: none;
  border: solid 1px;
  margin: 30px;
  padding: 10px 20px;
}

#news ul li a {
  color: var(--bs-dark);
  text-decoration: none;
}
</style>
