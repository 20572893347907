<template>
  <footer>
    <router-link to="/">
      <img id="footer-logo" src="@/assets/images/share/footer_logo.svg" />
    </router-link>
    <div class="row d-flex justify-content-center">
      <a href="https://twitter.com/fukunocha">
        <i class="bi bi-twitter"></i>
      </a>
      <a href="https://www.instagram.com/fukunocha/">
        <i class="bi bi-instagram"></i>
      </a>
      <a href="https://www.facebook.com/fukunocha">
        <i class="bi bi-facebook"></i>
      </a>
    </div>
    <div id="privacy-policy">
      <router-link to="/policy">プライバシーポリシー</router-link>
    </div>
    <p>運営会社:こまちソフトウェア株式会社</p>
    <p>秋田県秋田市東通仲町4-1 秋田拠点センターアルヴェ2階</p>
    <p>※当サイトの内容、テキスト、画像等の無断転載・無断使用を固く禁じます。</p>
  </footer>
</template>

<style scoped>
footer {
  color: white;
  height: 520px;
  background-color: #486930;
  background-image: url("@/assets/images/share/footer_back.png");
}

#footer-logo {
  margin: 30px 0;
  width: 100px;
}

footer a {
  width: auto;
  color: white;
  padding: 0 20px;
}

footer i {
  font-size: 2rem;
}

footer p {
  text-align: center;
}

#privacy-policy {
  margin: 20px 0px;
}

@media screen and (max-width: 576px) {
  footer p {
    text-align: center;
    font-size: 0.8rem;
  }
}
</style>
