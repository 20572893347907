import { createClient } from "microcms-js-sdk"; //ES6

const microCMSPlugin = {
  install: (app) => {
    const client = createClient({
      serviceDomain: process.env.VUE_APP_ROOT_SERVICEDOMAIN, // YOUR_DOMAIN is the XXXX part of XXXX.microcms.io
      apiKey: process.env.VUE_APP_ROOT_APIKEY,
    });
    app.provide("client", client);
  },
};
export default microCMSPlugin;
