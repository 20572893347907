<template>
  <div id="item">
    <header>
      <h1>商品紹介</h1>
    </header>
    <h2>抹茶で、至福・幸福の一時をあなたに。</h2>
    <div id="fukunocha" class="row justify-content-center justify-content-lg-end">
      <div class="col-lg-6 col-xl-5">
        <div class="picture">
          <img src="@/assets/images/item/item-image02.png" alt="商品の写真" />
        </div>
      </div>
      <div class="col-11 col-lg-6">
        <h3>福乃茶</h3>
        <div class="explanation">
          <p class="d-none d-md-block">
            京都・<span style="color: #4c7c2c">宇治</span>の茶葉のみから作られた<br />
            <span style="padding-left: 4%">
              「<span style="color: #bd6c47">安全・安心・高品質</span>」な抹茶です。
            </span>
          </p>
          <p class="d-block d-md-none">
            京都・<span style="color: #4c7c2c">宇治</span>の茶葉のみから作られた「
            <span style="color: #bd6c47">安全・安心・高品質</span>」な抹茶です。
          </p>
          <p>
            <span style="background: linear-gradient(transparent 70%, rgba(189, 108, 71, 0.7)); font-size: 1.6rem">
              1,500円(税込)
            </span>
          </p>
          <table>
            <tbody>
              <tr>
                <th>内容量</th>
                <td>30g(抹茶粉末)</td>
              </tr>
              <tr>
                <th>賞味期限</th>
                <td>製造日から1年</td>
              </tr>
              <tr>
                <th>保存方法</th>
                <td>冷蔵庫の中(15℃以下)で保存</td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>開封後は出来るだけ早く使い切ることをおすすめします。</li>
            <li>
              長期保存の場合、缶に入れた上、ラップ等で包んで冷凍庫へ。冷凍庫から出したお茶は丸一日かけ室温になじませてから開封してください。
            </li>
          </ul>
          <div class="mt-2">
            <div
              class="storesjp-button"
              data-storesjp-item="63c609aea37f7209c3a588d8"
              data-storesjp-variation="63d1fa33fd12d17967b70ca4"
              data-storesjp-name="fukunocha"
              data-storesjp-layout="layout_e"
              data-storesjp-lang="ja"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div id="irekata" class="row flex-row-reverse justify-content-center justify-content-lg-end">
      <div class="col-lg-6 col-xl-5">
        <div class="picture">
          <img src="@/assets/images/item/item-image03.png" alt="美味しそうに点てられた抹茶" />
        </div>
      </div>
      <div class="col-11 col-lg-6">
        <h4>○抹茶を美味しく淹れるコツ</h4>
        <div class="explanation">
          <ul>
            <li>ポイント</li>
            <p>①茶葉の量 ②美味しい水 ③湯の温度</p>
            <li>作り方</li>
            <ol>
              <li>沸騰させたお湯(90℃)を準備</li>
              <li>茶器とは別の器に注ぎ、10～20℃お湯を冷ましておく。</li>
              <li>
                粉末約2g(<ruby>茶杓<rt>ちゃしゃく</rt></ruby
                >2杯分/スプーン1杯分)をすくい、<ruby>茶漉<rt>ちゃこ</rt></ruby
                >しで濾しながら少しずつ茶器の中に入れる。
              </li>
              <li>
                冷ましておいたお湯を40mL注ぎ、<ruby>茶筅<rt>ちゃせん</rt></ruby
                >で点てる。
              </li>
              <p>詳細はページ下部にあるYoutube動画をご覧ください。</p>
            </ol>
          </ul>
        </div>
      </div>
    </div>

    <div id="charaku" class="row justify-content-center justify-content-lg-end">
      <div class="col-lg-6 col-xl-5">
        <div class="picture">
          <img src="@/assets/images/item/item-image04.jpg" alt="電動茶筅茶楽の写真" />
        </div>
      </div>
      <div class="col-11 col-lg-6">
        <h3>電動茶筅「茶楽(ちゃらく)」</h3>
        <div class="explanation">
          <p>
            ご家庭から業務まで、様々な場面でお使いいただける<span style="color: #4c7c2c">電動茶筅</span
            >です。抹茶を茶筅で点てる際に一苦労する動作を、スイッチ1つ・わずか<span style="color: #bd6c47">15秒</span
            >で美味しい抹茶を作ることができます。
          </p>
          <p>
            <span style="background: linear-gradient(transparent 70%, rgba(189, 108, 71, 0.7)); font-size: 1.6rem">
              11,000円(税込)
            </span>
          </p>
          <table>
            <tbody>
              <tr>
                <th>内容</th>
                <td>茶楽本体、茶筅、電池2本</td>
              </tr>
            </tbody>
          </table>
          <div
            class="storesjp-button"
            data-storesjp-item="63d1fa33fd12d17967b70ca3"
            data-storesjp-variation="63d1fa33fd12d17967b70ca4"
            data-storesjp-name="fukunocha"
            data-storesjp-layout="layout_e"
            data-storesjp-lang="ja"
          ></div>
        </div>
      </div>
    </div>

    <div id="osusume" class="row flex-row-reverse justify-content-center justify-content-lg-end">
      <div class="col-lg-6 col-xl-5">
        <div class="picture">
          <img src="@/assets/images/item/item-image05.jpg" alt="商品と抹茶" />
        </div>
      </div>
      <div class="col-lg-6">
        <h4>○おすすめポイント</h4>
        <div class="explanation">
          <ul>
            <li>茶楽を使うことで、きめ細かな泡を全体的に広げて作ることができ、出来栄えもより立派なものになります。</li>
            <li>お茶に馴染みのない海外の方へのお土産にも最適です。</li>
            <li>普通の抹茶以外にも、抹茶ラテを作る際など様々な用途でお使いいただけます。</li>
            <p>※バイブレーターの音が少し鳴りますので、お使いになられる際の環境にはご注意ください。</p>
          </ul>
        </div>
      </div>
    </div>

    <div id="willman" class="row justify-content-center justify-content-lg-end">
      <div class="col-lg-6 col-xl-5">
        <div class="picture">
          <img src="@/assets/images/item/item-image06.jpg" alt="電動茶筅茶楽の写真" />
          <div class="caption">
            <span>
              (株)ウィルマン 代表取締役 堀内氏(左)<br />
              こまちソフトウェア(株) 代表取締役 福嶋(右)
            </span>
          </div>
        </div>
      </div>
      <div class="col-11 col-lg-6 my-auto">
        <div class="explanation">
          <p>
            茶楽の販売元である<a href="https://www.willman.jp/electric-tea/">株式会社ウィルマン</a
            >の正規代理店です。<br />
            1年間の保証書も付いております。
          </p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-9 col-lg-6">
        <div class="iframe-wrapper">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/qvYkuSO93iw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
  (function (d, s, id) {
    if (d.getElementById(id)) {
      d.getElementById(id).remove();
    }
    var st = d.getElementById("app");
    console.log(st);
    var nst = d.createElement(s);
    nst.id = id;
    nst.src = "//btn.stores.jp/button.js";
    nst.charset = "UTF-8";
    st.appendChild(nst);
  })(document, "script", "storesjp-button");
});
</script>

<style scoped>
.button {
  margin-top: 20px;
}

header {
  position: relative;
  min-height: 50vw;
  background-image: url(@/assets/images/item/item-image01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

header h1 {
  font-size: 2.5rem;
  letter-spacing: 0.3rem;
  font-weight: bold;
  color: #486930;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  margin-top: 8%;
  padding: 10px 10px 10px 0px;
  padding-left: 8%;
}

#item h2 {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.4rem;
  color: #4f6e39;
  border-bottom: 1px dashed;
  padding: 10px 0px;
}

#fukunocha {
  margin-top: 100px;
}

#fukunocha .picture {
  position: relative;
  margin-right: 10%;
}

#fukunocha .picture::after {
  position: absolute;
  left: -10%;
  bottom: -30%;
  z-index: -1;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/images/item/item-image02-back.png);
  background-repeat: no-repeat;
  background-size: contain;
}

#fukunocha img {
  margin-top: 40px;
  width: 90%;
}

#fukunocha h3 {
  text-align: start;
  font-size: 2rem;
  border-bottom: 1.5px solid;
  padding: 10px 0px;
}

#fukunocha p {
  text-align: start;
  font-size: 1.3rem;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
}

#fukunocha .explanation {
  padding: 2% 15% 5% 5%;
}

#fukunocha table {
  text-align: start;
  width: 70%;
}

#fukunocha table th {
  position: relative;
}

#fukunocha table th::after {
  position: absolute;
  content: ":";
  left: 4.5rem;
}

#fukunocha table tr * {
  font-size: 1rem;
  font-weight: normal;
  line-height: 2rem;
}

#fukunocha ul {
  text-align: start;
  list-style-type: circle;
  color: #707071;
}

#irekata {
  margin-top: 100px;
}

#irekata .picture {
  position: relative;
  margin-right: 10%;
}

#irekata .picture::after {
  position: absolute;
  right: -10%;
  bottom: -30%;
  z-index: -1;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/images/item/item-image03-back.png);
  background-repeat: no-repeat;
  background-size: contain;
}

#irekata img {
  margin-top: 40px;
  width: 90%;
}

#irekata .explanation {
  padding: 2% 5% 5% 20%;
}

#irekata ul {
  text-align: start;
  list-style-type: disc;
}

#irekata ol li {
  line-height: 2rem;
}

#irekata ol p {
  margin-top: 15px;
}

#charaku {
  margin-top: 150px;
}

#charaku .picture {
  position: relative;
  margin-right: 10%;
}

#charaku .picture::after {
  position: absolute;
  left: -10%;
  bottom: -30%;
  z-index: -1;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/images/item/item-image02-back.png);
  background-repeat: no-repeat;
  background-size: contain;
}

#charaku img {
  margin-top: 40px;
  width: 90%;
}

#charaku h3 {
  text-align: start;
  font-size: 2rem;
  border-bottom: 1.5px solid;
  padding: 10px 0px;
}

#charaku p {
  text-align: start;
  font-size: 1.1rem;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
}

#charaku .explanation {
  padding: 2% 15% 5% 5%;
}
#charaku .hosoku {
  font-size: 1rem;
  line-height: normal;
  margin-top: 20px;
}

#charaku table {
  text-align: start;
  width: 70%;
}

#charaku table th {
  position: relative;
}

#charaku table th::after {
  position: absolute;
  content: ":";
  left: 2.5rem;
}

#charaku table tr * {
  font-size: 1rem;
  font-weight: normal;
  line-height: 2rem;
}

#osusume {
  margin-top: 150px;
}

#osusume .picture {
  position: relative;
  margin-right: 10%;
}

#osusume .picture::after {
  position: absolute;
  right: -10%;
  bottom: -30%;
  z-index: -1;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/images/item/item-image03-back.png);
  background-repeat: no-repeat;
  background-size: contain;
}

#osusume img {
  margin-top: 40px;
  width: 90%;
}

#osusume .explanation {
  padding: 2% 5% 5% 20%;
}

#osusume ul {
  text-align: start;
  list-style-type: disc;
}

#osusume ul li {
  line-height: 2rem;
  margin-bottom: 1rem;
}

#willman {
  margin-top: 150px;
}

#willman .picture {
  position: relative;
  margin-right: 10%;
}

#willman .caption span {
  text-align: start;
  position: absolute;
  bottom: 10px;
  right: 40px;
  display: inline;
  font-size: 0.6rem;
  font-weight: bold;
  color: white;
}

#willman .picture::after {
  position: absolute;
  left: -10%;
  bottom: -30%;
  z-index: -1;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/images/item/item-image02-back.png);
  background-repeat: no-repeat;
  background-size: contain;
}

#willman img {
  margin-top: 40px;
  width: 90%;
}

#willman h3 {
  text-align: start;
  font-size: 2rem;
  border-bottom: 1.5px solid;
  padding: 10px 0px;
}

#willman p {
  text-align: start;
  font-size: 1.1rem;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
}

#willman .explanation {
  padding: 2% 15% 5% 5%;
}

.iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin: 10vw 0;
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1140px) {
  #irekata .explanation {
    padding: 2% 5% 5% 10%;
  }

  #osusume .explanation {
    padding: 2% 5% 5% 10%;
  }
}

@media screen and (max-width: 992px) {
  #fukunocha {
    margin-top: 50px;
  }

  #fukunocha .picture {
    margin-right: 0;
  }

  #fukunocha .picture::after {
    all: initial;
  }
  #irekata {
    margin-top: 50px;
  }
  #irekata .picture {
    margin-right: 0;
  }
  #irekata .picture::after {
    all: initial;
  }
  #irekata h4 {
    font-size: 1.5rem;
    margin: 30px 0;
  }
  #charaku {
    margin-top: 50px;
  }
  #charaku .picture {
    margin-right: 0;
  }
  #charaku .picture::after {
    all: initial;
  }
  #osusume {
    margin-top: 50px;
  }
  #osusume .picture {
    margin-right: 0;
  }
  #osusume .picture::after {
    all: initial;
  }
  #osusume h4 {
    font-size: 1.5rem;
    margin: 30px 0;
  }
  #willman {
    margin-top: 50px;
  }
  #willman .picture {
    margin-right: 0;
  }
  #willman .picture::after {
    all: initial;
  }
}
@media screen and (max-width: 576px) {
  header {
    min-height: 600px;
    background-image: url(@/assets/images/item/item-image01-mobile.jpg);
  }

  #fukunocha p {
    text-align: start;
    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: 0.1rem;
  }
  #fukunocha .explanation {
    padding: 0;
  }

  #fukunocha table {
    text-align: start;
    width: 100%;
    margin-bottom: 20px;
  }

  #irekata .explanation {
    padding: 0;
  }

  #charaku .explanation {
    padding: 0;
  }

  #charaku h3 {
    font-size: 1.5rem;
    border-bottom: 1.5px solid;
  }

  #charaku p {
    text-align: start;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.1rem;
  }

  #charaku table th::after {
    position: absolute;
    content: ":";
    left: 2.1rem;
  }

  #osusume img {
    margin-top: 40px;
    width: 90%;
  }

  #osusume .explanation {
    padding: 0;
  }

  #osusume ul {
    text-align: start;
    list-style-type: disc;
  }

  #osusume ul li {
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  #willman .explanation {
    padding: 0;
  }

  #willman h3 {
    font-size: 1.5rem;
    border-bottom: 1.5px solid;
  }

  #willman p {
    text-align: start;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.1rem;
    margin-top: 30px;
  }
}
</style>
