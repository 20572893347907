<template>
  <div id="article" v-if="article">
    <h2>お知らせ</h2>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-xl-8">
        <h3>{{ article.title }}</h3>
        <p>{{ dayjs(article.createdAt).format("YYYY年MM月DD日") }}</p>
        <span v-html="article.content"></span>
      </div>
    </div>
    <div class="button-back">
      <router-link to="/news">ニュース一覧へ</router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";

import { useLoading } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
const $loading = useLoading();

const client = inject("client");

const article = ref();
const route = useRoute();
const router = useRouter();

(async () => {
  const loader = $loading.show();
  try {
    const response = await client.get({
      endpoint: "news",
      contentId: route.params.id,
    });
    article.value = response;
  } catch (e) {
    router.push({ path: "/error" });
  } finally {
    loader.hide();
  }
})();
</script>

<style scoped>
#article {
  margin-top: 100px;
}

#article h2 {
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 0.3rem;
}

#article h3 {
  font-size: 2rem;
  letter-spacing: 0.3rem;
  margin-top: 50px;
}

#article p {
  text-align: end;
  font-size: 1rem;
  margin: 0px 0px 50px 0px;
}

.button-back {
  margin: 50px 0px 50px 0px;
}
</style>
