<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand me-auto">
        <img src="@/assets/images/share/nav_logo.svg" />
      </router-link>
      <button
        type="button"
        class="btn btn-lg d-lg-none"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
          <button
            type="button"
            class="btn btn-lg btn-close ms-auto pe-5"
            data-bs-dismiss="offcanvas"
            aria-label="閉じる"
          ></button>
        </div>
        <ul class="navbar-nav ms-lg-auto mb-2 mb-lg-0">
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <router-link to="/" class="nav-link" aria-current="page">{{ $t("home") }}</router-link>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <router-link to="/concept" class="nav-link">コンセプト</router-link>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <router-link to="/item" class="nav-link">商品紹介</router-link>
          </li>
          <!-- <li class="nav-item" data-bs-dismiss="offcanvas">
            <router-link to="/contact" class="nav-link">お問い合わせ</router-link>
          </li> -->
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <a href="https://fukunocha.stores.jp/" class="nav-link">ストア</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<style lang="scss" scoped>
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

nav {
  height: 100px;
  background-image: url(@/assets/images/share/nav_back.svg);
  background-size: cover;
}

nav .navbar-brand img {
  text-align: start;
  margin-left: 50px;
  max-width: 200px;
  height: 70px;
}

ul li {
  font-size: 1.3rem;
  margin: 20px 30px 0 0;
}

@media screen and (max-width: 1200px) {
  ul li {
    font-size: 1.2rem;
    margin: 20px 20px 0 0;
  }
}

@media screen and (max-width: 992px) {
  nav {
    height: 80px;
    padding: 10px;
  }
  nav .navbar-brand img {
    margin-left: 0;
    height: 50px;
  }
  ul li {
    font-size: 1.3rem;
    margin: 20px 30px 0 0;
  }
}

@media screen and (max-width: 576px) {
}
</style>
